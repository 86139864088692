.text-with-facts {
    padding-top: 6rem;
    padding-bottom: 6rem;
    .facts-column {
        display: flex;
        flex-wrap: wrap;
    }
    .header-column {
        color: $color-dk-blue;
        h2 {
            font-weight: 500;
        }
    }
    .body-column {
        padding-right: 3rem;
        p {
            font-weight: 500;
        }
    }
    .fact-header {
        color: $color-dk-blue;
        font-size: 83px;
        line-height: 36px;
        margin-bottom: 2.5rem;
        margin-top: 2.5rem;
        font-weight: 500;
        @include media-breakpoint-down(sm) {
            font-size: 63px;
        }
    }
    .fact {
        border-bottom: solid $color-text-grey 1px;
        margin-right: 1.5rem;
    }
    .fact-text {
        font-weight: 500;
    }
}