* {
    margin: 0;
}

html {
    overflow-x: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Work+Sans:wght@300;400;500&display=swap');
//Colors
$color-white: #ffffff;
$color-black: #131516;
$color-green: #6ADD9A;
$color-dk-blue: #100EA0;
$color-line-blue: #2D2BFF;
$color-text-grey:#657687;
$color-lt-grey-background: #F5F5F5;
$theme-max-width: 1366px;
$theme-colors: ("info": tomato,
"primary": teal);
//Font Sizes
$font-size-base: 1.125rem;
$h1-font-size: 43px;
$h2-font-size: 28px;
$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 12px;
$font-weight-bolder: 500;
//breakpoints
$grid-breakpoints: (xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1400px);
//Spacers
$spacer: 1rem;
$spacers: ( 0: 0,
1: $spacer * .25,
2: $spacer * .5,
3: $spacer,
4: $spacer * 2,
5: $spacer * 3,
);
$body-color: $color-text-grey;
$font-family-sans-serif: 'Montserrat',
sans-serif;
$font-family-base: $font-family-sans-serif;
// Buttons
$btn-active-bg-shade-amount: 0;
$btn-active-bg-tint-amount:0;
$btn-active-border-shade-amount:0;
$btn-active-border-tint-amount:0;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;
$btn-border-radius: 0;
$btn-border-width: none;
//EDIT ME
$input-btn-focus-color: $color-white;
//Heading
$headings-font-family: 'Montserrat',
sans-serif;
$headings-font-weight: 500;
$link-hover-decoration: none;
@import "~bootstrap/scss/bootstrap";
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    overflow-x: hidden;
    background-color: $color-lt-grey-background;
}

h1 {
    line-height: 54px;
    font-size: 43px;
    @include media-breakpoint-down(sm) {
        font-size: 36px;
        line-height: 45px;
    }
}

h2 {
    line-height: 36px;
    font-size: 28px;
    @include media-breakpoint-down(sm) {
        font-size: 23px;
        line-height: 29px;
    }
}

h3 {
    line-height: 40px;
    @include media-breakpoint-down(sm) {
        font-size: 23px;
        line-height: 29px;
    }
}

h4 {
    line-height: 26px;
}

h5 {
    line-height: 14px;
}

h6 {}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

p {
    line-height: 28px;
    font-size: 16px;
    @include media-breakpoint-down(sm) {
        line-height: 26px;
    }
}

a {
    line-height: 28px;
    font-size: 16px;
    @include media-breakpoint-down(sm) {
        line-height: 26px;
    }
}

@import "./global.scss";
@import "./footer.scss";
@import "./blocks/Header.scss";
@import "./blocks/HomeHeroBlock.scss";
@import "./blocks/TextWithFactsBlock.scss";
@import "./blocks/ImageWithListBlock.scss";
@import "./blocks/HeaderBlock.scss";
@import "./blocks/CompanyBlock.scss";
@import "./blocks/HeaderButtonBlock.scss";
@import "./blocks/CareersHeroBlock.scss";
@import "./blocks/ImageWithFactsBlock.scss";
@import "./blocks/ListColumnBlock.scss";
@import "./blocks/QuoteBlock.scss";
@import "./blocks/CareerBlock.scss";
@import "./blocks/MapBlock.scss";
@import "./blocks/CookiePolicy.scss";