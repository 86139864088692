.image-with-list {
    padding: 0;
    max-width: 100%;
    background-color: $color-black;
    .row {
        max-width: $theme-max-width;
        margin: 0 auto;
        padding: 0 3rem;
        padding-left: 0;
        @include media-breakpoint-down(sm) {
            padding-left: 1.5rem;
            padding-left: 1.5rem;
        }
    }
    .image-container {
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
    }
    .image-col {
        padding: 0;
    }
    .info-container {
        padding: 1.5rem;
        padding-top: 6rem;
        padding-bottom: 6rem;
        padding-left: 5rem;
        @include media-breakpoint-down(md) {
            padding-left: 3rem;
        }
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .header {
        color: white;
        margin-bottom: 3rem;
        padding-bottom: 3rem;
        border-bottom: solid $color-dk-blue 1px;
    }
    .list-col {
        margin-left: 0;
        padding: 0;
    }
    .list-container {
        padding: 0;
    }
    .list-item {
        margin-left: 0;
        color: $color-white;
        margin-bottom: 0.5rem;
    }
}