.list-column-block {
    padding-top: 0;
    margin-top: 0;
    padding: 0;
    max-width: 100%;
    background-color: $color-black;
    .row {
        padding: 0 3rem;
        max-width: $theme-max-width;
        margin: 0 auto;
        @include media-breakpoint-down(sm) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
    .header-wrapper {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    h1 {
        color: white;
        margin-bottom: 2rem;
    }
    .list-container {
        border-top: solid $color-dk-blue 1px;
        margin-bottom: 4rem;
        h2 {
            color: white;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        p {
            margin-top: 0;
            margin-bottom: 0;
        }
        ul {
            padding: 0;
        }
        li {
            list-style: none;
            color: $color-white;
        }
    }
}