.footer {
    background-color: $color-black;
    .row {
        max-width: $theme-max-width;
        margin-left: auto;
        margin-right: auto;
    }
    .footer-logo {
        display: flex;
        align-items: center;
    }
    .logo-container {
        height: 75%;
        width: 75%;
        margin-top: 3rem;
        margin-bottom: 3rem;
        margin-left: 2rem;
    }
    svg {
        height: 100%;
        width: 100%;
    }
    .information {
        text-align: right;
        margin-top: 3rem;
        margin-bottom: 3rem;
        font-weight: 500;
        p {
            margin-bottom: 0;
        }
        a {
            color: $color-text-grey;
            text-decoration: none;
            transition: .4s ease color;
            &:hover {
                color: $color-white;
            }
        }
        .row {
            padding-top: 3rem;
            a {
                text-decoration: underline;
            }
            .link-menu {
                &:last-child {
                    padding-right: 0;
                }
            }
        }
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }
    .social {
        margin-top: 3rem;
    }
    .icon {
        svg {
            fill: $color-line-blue;
            height: 42px;
            transition: .4s all ease;
            &:hover {
                fill: $color-white;
                cursor: pointer;
            }
        }
    }
}

.step-wrapper {
    width: 100%;
    overflow: hidden;
    height: 212px;
    z-index: 0;
    svg {
        min-width: 2700px;
    }
}