.header-block {
    padding-top: 6rem;
    padding-bottom: 6rem;
    max-width: 100%;
    background-color: $color-dk-blue;
    @include media-breakpoint-down(sm) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .row {
        max-width: $theme-max-width;
        margin: 0 auto;
    }
    .sub-header {
        margin-bottom: 2rem;
    }
    .header {
        color: white;
    }
}