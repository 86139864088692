.nav-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 3rem;
    @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
    }
    .logo {
        margin-left: $spacer;
    }
    svg {
        width: 235px;
        @include media-breakpoint-down(sm) {
            width: 200px;
        }
        path,
        rect {
            transition: .4s all ease;
        }
        &:hover {
            path,
            rect {
                transition: .4s all ease;
                fill: rgba($color-white, .6);
            }
        }
    }
    .row {
        max-width: $theme-max-width;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;
    }
    .button-col {
        display: flex;
        justify-content: right;
    }
    .button-container {
        display: inline-block;
        margin-right: 2rem;
        margin-left: 1rem;
        transition: border 1s;
        padding: 0.5rem 0;
        width: 190px;
        text-align: center;
        border: 1px solid transparent;
        @include media-breakpoint-down(md) {
            margin-right: 0;
        }
        &.active,
        &:hover {
            background: rgba($color-white, .5);
            border: 1px solid $color-dk-blue;
            .button {
                color: $color-dk-blue;
                border-bottom-color: transparent;
            }
        }
        &.active {
            cursor: initial;
        }
    }
    .button {
        background: none;
        border: none;
        padding: 0;
        margin-right: $spacer;
        align-self: right;
        transition: color 1s;
        border-bottom: solid white 1px;
    }
    #career-button {
        margin: 0;
        padding: 0;
        display: inline-block;
    }
}

.careers {
    .nav-bar {
        svg {
            transition: .4s all ease;
            path,
            rect {
                transition: .4s all ease;
                fill: $color-text-grey;
            }
            &:hover {
                path,
                rect {
                    transition: .4s all ease;
                    fill: rgba($color-text-grey, .6);
                }
            }
        }
    }
}