.image-with-facts {
    border-top: 1px solid #0A1999;
    max-width: 100%;
    .row {
        max-width: $theme-max-width;
        margin: 0 auto;
    }
    .facts-row {
        margin-top: 2rem;
    }
    .image-container {
        max-width: 480px;
        padding: 0;
    }
    .header {
        color: $color-dk-blue;
        margin-bottom: 2rem;
        @include media-breakpoint-down(lg) {
            padding-top: 2rem;
        }
    }
    .image {
        width: 100%;
    }
    .fact-header {
        color: $color-dk-blue;
        font-size: 83px;
        line-height: 36px;
        margin-bottom: 2.5rem;
        margin-top: 2.5rem;
        font-weight: 500;
        @include media-breakpoint-down(sm) {
            font-size: 63px;
        }
    }
    .fact {
        border-bottom: solid $color-text-grey 1px;
        margin-right: 1.5rem;
    }
    .fact-text {
        font-weight: 500;
    }
}