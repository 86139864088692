.home-hero-block {
    .row {
        height: 100%;
        max-width: $theme-max-width;
        margin: 0 auto;
    }
    .header {
        color: white;
    }
}

.background-image {
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    max-height: 800px;
    max-width: 100%;
    width: 100%;
}
