.quote-block {
    .row {
        padding-left: 3rem;
        margin-bottom: 4rem;
        @include media-breakpoint-down(sm) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
    .col {
        padding: 0;
        margin: 0;
    }
    h2 {
        margin-bottom: 3rem;
        color: $color-dk-blue;
    }
    p {
        margin: 0;
    }
    .quote-col {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 1.5rem;
        @include media-breakpoint-down(md) {
            margin-bottom: 2rem;
        }
    }
    .quote-container {}
    .image-container {
        max-width: 509px;
        padding: 0;
        margin: 0;
    }
    .image {
        width: 100%;
    }
}