.company-block {
    padding-top: 6rem;
    padding-bottom: 6rem;
    .company-col {
        height: 197px;
        padding: 1rem;
    }
    .company-col:hover {
        border-color: $color-dk-blue;
        cursor: pointer;
    }
    .logo-container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-text-grey;
        background-color: $color-white;
    }
    .company-logo {
        max-width: 190px;
        width: 100%;
        padding: 0.5rem;
    }
}

.modal {
    .close {
        position: absolute;
        text-align: right;
        right: 0;
        top: 15px;
        width: 66px;
        height: 32px;
        opacity: 0.3;
    }
    .close:hover {
        opacity: 1;
        cursor: pointer;
    }
    .close:before,
    .close:after {
        position: absolute;
        right: 35px;
        content: ' ';
        height: 33px;
        width: 2px;
        background-color: #333;
    }
    .close:before {
        transform: rotate(45deg);
    }
    .close:after {
        transform: rotate(-45deg);
    }
    .pop-url {
        margin-left: 1rem;
        padding: 0;
        width: auto;
    }
}

.popover {
    min-width: 600px;
    border: solid $color-dk-blue 1px;
    border-radius: 2px;
}

.pop-company-logo {
    font-size: 2rem;
    max-width: 190px;
}

.pop-body {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.pop-company-name {
    text-decoration: none;
    color: $color-dk-blue;
}

.pop-url {
    text-decoration: none;
    color: $color-dk-blue;
    border-bottom: solid #b1c4e3 2px;
    padding-bottom: .4rem;
    margin-bottom: 2rem;
}

.pop-company-info {
    margin-bottom: 2rem;
}

.bs-popover-bottom .popover-arrow:before {
    border-bottom-color: $color-dk-blue;
}

.bs-popover-bottom .popover-arrow {
    top: calc(-.5rem - 1px);
}