.careers-hero-block {
    padding-bottom: 0;
    @include media-breakpoint-down(sm) {
        padding-top: 6rem;
    }
    .top-header {
        color: $color-dk-blue;
        margin-top: 3rem;
    }
    h1 {
        @include media-breakpoint-down(md) {
            padding-top: 2rem;
        }
    }
    .header-col {
        display: flex;
        align-items: center;
        padding-left: 3rem;
    }
    .button {
        margin-top: 4rem;
        margin-bottom: 4rem;
        height: 53px;
        width: 299px;
        background: none;
        border: solid $color-dk-blue 2px;
        border-radius: 6px;
        color: $color-dk-blue;
        background: linear-gradient(to right, $color-dk-blue 50%, $color-lt-grey-background 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        transition: all .5s ease-out;
    }
    .button:hover {
        background-position: left bottom;
        color: white;
    }
    .image-col {
        padding: 0;
        max-height: 800px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    .image-container {
        padding: 0;
        margin: 0;
    }
    .image {
        width: 100%;
    }
}