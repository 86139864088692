.career-block {
    padding: 6rem;
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid #0A1999;
    .career-wrapper {
        margin: 0 auto;
        padding: 3rem;
        background-color: $color-lt-grey-background;
        .row {
            max-width: $theme-max-width;
            margin: 0 auto;
        }
    }
    .header-row {
        h1 {
            color: $color-dk-blue;
            font-weight: 500;
            margin-bottom: 2rem;
        }
        h6 {
            font-weight: 400;
            margin-bottom: 3rem;
        }
    }
    .job-count {
        font-size: 13px;
        font-weight: 700;
        margin-top: 2rem;
    }
    .sorter-row {
        z-index: 1;
        position: relative;
    }
    .dropdown {
        @include media-breakpoint-down(md) {
            margin-top: 2rem;
        }
        .category-toggle {
            text-decoration: none;
            background-color: $color-white;
            border: 1px solid $color-dk-blue;
            padding: 5px 10px;
            height: 40px;
            min-width: 200px;
            display: block;
            font-weight: 500;
            color: $color-black;
            &:hover {
                border-color: $color-line-blue;
                background-color: $color-dk-blue;
                color: $color-white;
                cursor: pointer;
            }
            &::after {
                color: $color-line-blue;
                float: right;
                margin-top: 10px;
            }
        }
        .dropdown-menu {
            .category-item {
                &.active,
                &:active {
                    background-color: $color-dk-blue;
                }
            }
        }
    }
    .career-list-wrapper {
        .career-list-row {
            // background-color: $color-lt-grey;
            border-top: 1px solid $color-text-grey;
            padding: 1.5rem 0rem;
            border-bottom: none;
            &:last-child {
                border-bottom: 1px solid $color-text-grey;
                padding-bottom: 2rem;
            }
            .link {
                text-align: right;
                a {
                    text-decoration: none;
                    color: $color-line-blue;
                    border-bottom: 2px solid $color-line-blue;
                    display: inline-block;
                    transition: all .2s ease;
                    height: 30px;
                    font-size: 14px;
                    font-weight: 700;
                    &:hover {
                        color: $color-dk-blue;
                        border-bottom: 2px solid $color-dk-blue;
                    }
                }
            }
        }
        .career-list-text,
        .location,
        .team {
            h3,
            h4 {
                margin: 0;
            }
        }
        .title-row {
            padding: 3rem 0rem;
            background-color: transparent;
        }
    }
    .small-text {
        p {
            font-size: 13px;
        }
    }
}